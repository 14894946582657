import React from 'react'

import Meta from 'components/meta'
import Wrapper from 'components/wrapper'

interface Props {
  location: Location
}

const Stream_2021_12_18 = ({ location }: Props) => {
  return (
    <Wrapper location={location} className="w-100 mx-auto d-flex flex-column bg-body-darker">
      <Meta title="Virtual Dec 2020" />
        <div className="embed-responsive embed-responsive-16by9">
          <iframe
            className="py-5 position-absolute top-0 start-0 h-100 w-100"
            src="https://player.vimeo.com/video/495554834?title=0&byline=0&portrait=0"
            frameBorder="0"
            allow="fullscreen"
            allowFullScreen
          ></iframe>
        </div>
    </Wrapper>
  )
}

export default Stream_2021_12_18
